import { styled } from 'styled-components'
import QuantityInputBridgeIn from './QuantityInputBridgeIn'
import { useAccount, useBalance } from 'wagmi'
import { setDPSBalance, setConfirmDPS } from '@/features/bridge/bridgeSlice'
import { useEffect, useState } from 'react'
import { useContractAddress } from '@/hooks/common'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import useSWR from 'swr'
import { BridgeLocation } from '@/entities/bridgeLocation'
import { FetchBridgeOutData } from '@/utils/apiFetching'

const AvailableDPS = () => {
  const dispatch = useAppDispatch()

  const { address } = useAccount()
  const { chainFromStatus, chainToStatus, dpsBalance } = useAppSelector((state: any) => state.bridge)

  const token: string | undefined = useContractAddress()
  const [bridgeInDps, setBridgeInDps] = useState('')
  const [availDps, setAvailDps] = useState(0)

  const balanceFromChain = useBalance({ address, token: token as `0x${string}` | undefined, watch: true })
  const { data: bridgeOutAssets } = useSWR(
    [`BridgeOut`, chainToStatus, address],
    () => FetchBridgeOutData(chainToStatus as BridgeLocation, address as string)
  )

  useEffect(() => {
    setAvailDps(dpsBalance)
    setBridgeInDps('')
    dispatch(setConfirmDPS('Confirm DPS'))
  }, [dpsBalance, dispatch])

  useEffect(() => {
    let balance = '0'
    if (chainFromStatus === BridgeLocation.Game ) {
      if (bridgeOutAssets)
        balance = (
          Math.floor(
            parseFloat(bridgeOutAssets?.bridgableItems?.dpsBalance ?? '0') /
            Math.pow(10, Number(balanceFromChain.data?.decimals)) * 10000
          )/10000
        ).toFixed(4)
    } else if (chainToStatus && balanceFromChain) {
      balance = (Math.floor(parseFloat(balanceFromChain?.data?.formatted ?? '0') * 10000)/10000).toFixed(4)
    }
    dispatch(setDPSBalance(Number(balance)))
  }, [chainFromStatus, chainToStatus, bridgeOutAssets, balanceFromChain, dispatch])

  const onChangeDPSQuantity = (e: any) => {
    const re = /^(?:\d+(?:\.\d{1,4})?|\.\d{1,4})?$/
    if (e.target.value === '') {
      setBridgeInDps('')
      setAvailDps(dpsBalance)
      dispatch(setConfirmDPS('Confirm DPS'))
    } else {
      if (re.test(e.target.value)) {
        if (dpsBalance >= Number(e.target.value)) {
          const calAvailableDps = (dpsBalance - Number(e.target.value)).toFixed(2)
          setAvailDps(Number(calAvailableDps))
          setBridgeInDps(e.target.value)
          dispatch(setConfirmDPS(Number(e.target.value).toString()))
        }
      }
    }
  }

  const onClickMaxDPS = () => {
    setBridgeInDps(dpsBalance.toString())
    setAvailDps(0)
    dispatch(setConfirmDPS(dpsBalance.toString()))
  }

  return (
    <>
      <AmountTitle>Available DPS</AmountTitle>
      <div className="available-dps">
        <AmountContainer>
          <Amount></Amount>
          <AmountValue>
            {' '}
            <AmountNumber>{availDps}</AmountNumber>
            {' '}
            <AmountLabel>{`DPS`}</AmountLabel>
          </AmountValue>
        </AmountContainer>
        <Spancer></Spancer>
        <QuantityInputBridgeIn
          onChange={onChangeDPSQuantity}
          onClick={onClickMaxDPS}
          bridgeInDps={bridgeInDps}
        ></QuantityInputBridgeIn>
      </div>
    </>
  )
}

export default AvailableDPS

const AmountTitle = styled.span`
  font-size: 18px;
`
const AmountContainer = styled.div`
  width: 190px;
  height: 30px;
  border: solid 2px #00aeee;
  position: relative;
`
const Amount = styled.div`
  width: 188px;
  height: 28px;
  background: #00aeee;
  opacity: 0.6;
`
const AmountValue = styled.span`
  position: absolute;
  top: 2px;
  color: white;
  left: 3px;
`
const AmountNumber =  styled.span`
  display:inline-block;
  overflow-y:hidden;
  @media (max-width: 1035px) {
    max-width: 80px;
  }
  @media (min-width: 1036px) {
    max-width: 140px;
  }
`
const AmountLabel =  styled.span`
  display:inline-block;
  vertical-align: top;  
`
const Spancer = styled.div`
  width: 60px;
  height: 1px;
  background: #00aeee;
`
